import React from 'react';

import SEO from '../../components/layout/SEO';

// Used as a redirect for an existing url sent out to clients to link to good cause PDF
const GoodCausePack = () => (
    <SEO
        allowIndexing={false}
        redirect="https://roundups-marketing-site.s3-eu-west-1.amazonaws.com/PDFs/How+Roundups+Works+for+Good+Causes.pdf"
    />
);

export default GoodCausePack;
